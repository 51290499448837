(function ($) {
  Drupal.behaviors.skuSelectV1 = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /**
     * Creates a div element with the proper class names
     * and shade backround for a given sku Id.
     * @param {string} skuId - the SKU id to use.
     */
    makeSwatch: function (skuId) {
      var sku = prodcat.data.getSku(skuId);
      var $swatchDiv = $();

      if (sku && sku.HEX_VALUE_STRING) {
        $swatchDiv = $('<div/>', {
          class: 'select-box__swatch js-select-box-swatch',
          style: 'background-color: ' + sku.HEX_VALUE_STRING + ';'
        });
      }

      return $swatchDiv;
    },

    /**
     * Inserts little swatches into each option of the js-select-box.
     *  @type {Object} context - the DOM scope, usually document.
     */
    setupSwatches: function (context) {
      var self = this;
      var $shadeSelect = $('.js-sku-menu.js-select-box', context);

      if (!$shadeSelect.length) {
        return;
      }
      //       console.log("SET UP SWATCH");
      //       console.log($shadeSelect);

      // There may be more than one shade sku select on the page.
      $shadeSelect.each(function () {
        var $optionsWrapper = $('.js-select-box-options', $(this));

        if ($optionsWrapper != null) {
          var $options = $optionsWrapper.find('.js-select-box-option');

          $('.js-select-box-swatch', $options).remove();
          $options.each(function () {
            var $option = $(this);
            var skuId = $option.data('sku_base_id');
            var $swatch = self.makeSwatch(skuId);

            $option.append($swatch);
          });
        }
      });
    },

    /** Sets up events for this template.
     *  @type {Object} context - the DOM scope, usually document.
     */
    setEvents: function (context) {
      var self = this;

      // When the menu changes, trigger skuSelect:
      $(document).on('change', 'select.js-sku-menu', function () {
        var skuId = $(this).val();
        var sku = prodcat.data.getSku(skuId);
        var skuBaseId;

        if (sku) {
          skuBaseId = sku.SKU_BASE_ID;
          $(this).closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
        }
      });

      // Likewise, whenever skuSelect is fired on a product, update the menu:
      $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
        $('select.js-sku-menu', this).val(skuBaseId);

        // Price update logic
        var sku = prodcat.data.getSku(skuBaseId);

        if (sku) {
          // Need to wrap sku inside of defaultSku because thats where the template reads
          var content = site.template.get({
            name: 'product_sku_price',
            data: { defaultSku: sku }
          });

          $('.product-sku-price', this).html($(content).html());
        }
      });

      // On quickshop load, set up the swatches in the select box.
      $(document).on('product.quickshop.animation.before', '.js-product-grid', function () {
        var $grid = $(this);

        self.setupSwatches($grid);
      });
    },

    /**
     * Standard method for attaching the template's JS.
     * @param {Object} context - the DOM scope, usually document.
     * @param {Object} settings - useful collection of site info.
     */
    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      // Add swatches to select box, only if desktop.
      // Mobile uses the device's select styling.
      if ($('html').hasClass('no-touchevents')) {
        this.setupSwatches(context);
      }

      this.setEvents(context);
    }
  };
})(jQuery);
